<template>
  <div class="sort-wrap">
    <ul v-if="viewOptions.length" :style="{paddingTop: paddingTop}">
      <li
        v-for="(item, index) in viewOptions"
        :key="item.field"
        :class="{ active: !!item.order }"
        @click="onChange(item, index)"
      >
        {{ item.text }}
        <van-icon
          v-if="item.order"
          color="#E98F36"
          class="play"
          name="play"
          :class="{ ascend: item.order == 'ascend' }"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    paddingTop: {
      type: String,
      default: '10px'
    }
  },
  data() {
    return {
      viewOptions: this.options,
    }
  },
  watch: {
    options(val) {
      this.viewOptions = val
    },
  },
  methods: {
    onChange(item, index) {
      let { order, field } = item
      if (order == 'ascend') {
        order = 'descend'
      } 
      // else if (order == 'descend') {
      //   order = ''
      // } 
      else {
        order = 'ascend'
      }
      let arr = []
      if (this.multiple) {
        // 多选
        this.viewOptions.splice(index, 1, { ...item, order })
        this.viewOptions.forEach((element) => {
          if (element.order) {
            arr.push({ field: element.field, asc: element.order == 'ascend' })
          }
        })
      } else {
        // 单选
        this.viewOptions = this.viewOptions.map((element) => {
          return { ...element, order: element.field === field ? order : '' }
        })
        if (order) {
          arr = [{ field: item.field, asc: order == 'ascend' }]
        }
      }
      this.$emit('change', arr)
    },
  },
}
</script>
<style lang="less">
.sort-wrap {
  background: white;
  ul {
    overflow-y: auto;
    display: flex;
    padding: 12px 0;
    li {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 4px 8px;
      border-radius: 4px;
      background: #f2f2f2;
      margin-left: 16px;
      font-size: 14px;
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
      &.active {
        background: #faefdc;
        color: #df9d40;
      }
      .play {
        transform: rotate(90deg);
        margin-left: 4px;
        &.ascend {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
</style>
