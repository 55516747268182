import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import baseDataServices from '@/services/baseDataServices'
import salesAssistantService from '@/services/salesAssistant'
import invalidManageServices from '@/services/invalidManageServices.js'
import formatParams from '@/utils/formatParams'
import clueMixin from '@/modules/clue/list/mixin'
export default {
  data() {
    return {
      filterPanelVisible: false,
      filterOptions: [],
    }
  },
  mixins: [clueMixin],
  computed: {
    ...mapGetters([
      'dictHash',
      'userInfo'
    ]),
    isDealer() {
      return this.userInfo.type === 1
    },
  },
  watch: {
    dictHash: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.isClue) {
            this.createClueFilterOptions()
          } else {
            this.createFilterOptions()
          }
        }
      },
    }
  },
  methods: {
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      if (this.filterParams.distributeTimeStart || this.filterParams.distributeTimeEnd){
        this.$set(this.filterParams,'distributeTime',{
          start: this.filterParams.distributeTimeStart,
          end: this.filterParams.distributeTimeEnd,
        })
      }
      if (this.filterParams.defeatTimeStart || this.filterParams.defeatTimeEnd){
        this.$set(this.filterParams,'defeatTime',{
          start: this.filterParams.defeatTimeStart,
          end: this.filterParams.defeatTimeEnd,
        })
      }
      if (this.filterParams.invalidTimeStart || this.filterParams.invalidTimeEnd){
        this.$set(this.filterParams,'invalidTime',{
          start: this.filterParams.invalidTimeStart,
          end: this.filterParams.invalidTimeEnd,
        })
      }
      if (this.filterParams.recycleTimeStart || this.filterParams.recycleTimeEnd){
        this.$set(this.filterParams,'recycleTime',{
          start: this.filterParams.recycleTimeStart,
          end: this.filterParams.recycleTimeEnd,
        })
      }
      if (this.filterParams.drivingTimeStart || this.filterParams.drivingTimeEnd){
        this.$set(this.filterParams,'drivingTime',{
          start: this.filterParams.drivingTimeStart,
          end: this.filterParams.drivingTimeEnd,
        })
      }
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 搜索面板条件
    async createFilterOptions() {
      const ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      if (!this.isDealer) {
        const { parentCode='',
          childCode='',
          dealerProvinceCode='',
          dealerCityCode='',
          dealerIds=''
        } = this.$route.query
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          defaultValue: parentCode,
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          defaultValue: childCode,
          field: 'childCode',
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('省份'),
          field: 'dealerProvinceCode',
          defaultValue: dealerProvinceCode,
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市'),
          field: 'dealerCityCode',
          defaultValue: dealerCityCode,
          options: [],
        })
        // 销售门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '' })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('销售门店'),
          multiple: true,
          field: 'dealerIds',
          defaultValue: dealerIds,
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      if (this.tabValue === 0) {
        ret.push({
          id: uuidv4(),
          type: 'dataRange',
          label: this.$t('下发时间'),
          dateType: 'datetime',
          start: {
            field: 'distributeTimeStart',
          },
          end: {
            field: 'distributeTimeEnd',
          },
          timeOptions: [
            { value: 0, key: 'day', text: '今天' },
            { value: 1, key: 'hour', text: '1小时内' },
          ]
        })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('线索归属'),
          field: 'sourceType',
          multiple: true,
          options: getOptionsByDictType(1007),
        })
      }
      if (this.tabValue === 1) {
        ret.push({
          id: uuidv4(),
          type: 'dataRange',
          label: this.$t('超期回收时间'),
          dateType: 'datetime',
          start: {
            field: 'recycleTimeStart',
          },
          end: {
            field: 'recycleTimeEnd',
          }
        })
        ret.push({
          id: uuidv4(),
          type: 'dataRange',
          label: this.$t('最新试驾时间'),
          dateType: 'datetime',
          start: {
            field: 'drivingTimeStart',
          },
          end: {
            field: 'drivingTimeEnd',
          }
        })
        if (this.isDealer) {
          const dccStaffList = await baseDataServices.getStaffList({ roleLogo: '1014001,1014002', dealerId: this.userInfo.dealers[0]?.id })
          ret.push({
            id: uuidv4(),
            type: 'options',
            label: this.$t('原跟进销售顾问'),
            multiple: true,
            field: 'followUserIds',
            options: dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id })),
          })
        }
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('试驾情况'),
          multiple: false,
          field: 'testDrivingCount',
          options: [
            { id: uuidv4(), label: this.$t('否'), value: 0 },
            { id: uuidv4(), label: this.$t('1次'), value: 1 },
            { id: uuidv4(), label: this.$t('多次'), value: 2 },
          ],
        })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('购车意向等级'),
          multiple: true,
          field: 'leadLevels',
          options: [
            { id: uuidv4(), label: 'H', value: 'H' },
            { id: uuidv4(), label: 'A', value: 'A' },
            { id: uuidv4(), label: 'B', value: 'B' },
            { id: uuidv4(), label: 'C', value: 'C' },
            { id: uuidv4(), label: 'U', value: 'U' },
            { id: uuidv4(), label: '未评级', value: '' },
          ],
        })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('试驾意向等级'),
          multiple: true,
          field: 'tryDriveLevels',
          options: [
            ...getOptionsByDictType(1012),
            { id: uuidv4(), label: '未评级', value: '' },
          ],
        })
      }
      // 车系车型
      ret.push({
        id: uuidv4(),
        type: 'cars',
        label: this.$t('意向车型'),
        field: 'cars',
        tagsInput: true,
        fields: ['seriesCodes', 'modelCodes'],
      })
      // 渠道
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('用户来源'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
      })
      this.filterOptions = ret
    },
    // 战败/无效 搜索面板条件
    async createDefeatFilterOptions(type) {
      const ret = []
      const dictHash = this.$store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      if (!this.isDealer) {
        const parentList = await salesAssistantService.getRegionTreeApi()
        // 大区
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('大区'),
          field: 'parentCode',
          options: [],
          options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
        })
        // 城市区域
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市区域'),
          multiple: true,
          field: 'childCode',
          options: [],
        })
        const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
        // 省份
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('省份'),
          field: 'dealerProvinceCode',
          options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
        })
        // 城市
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('城市'),
          field: 'dealerCityCode',
          options: [],
        })
        // 销售门店
        const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '' })
        ret.push({
          id: uuidv4(),
          type: 'selectOptions',
          label: this.$t('销售门店'),
          multiple: true,
          field: 'dealerIds',
          options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
        })
      }
      if (type === 'defeat') {
        ret.push({
          id: uuidv4(),
          type: 'dataRange',
          label: this.$t('最新战败时间'),
          dateType: 'datetime',
          start: {
            field: 'defeatTimeStart',
          },
          end: {
            field: 'defeatTimeEnd',
          }
        })
      }
      if (type === 'invalid') {
        ret.push({
          id: uuidv4(),
          type: 'dataRange',
          label: this.$t('最新无效时间'),
          dateType: 'datetime',
          start: {
            field: 'invalidTimeStart',
          },
          end: {
            field: 'invalidTimeEnd',
          }
        })
      }
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        label: this.$t('最新试驾时间'),
        dateType: 'datetime',
        start: {
          field: 'drivingTimeStart',
        },
        end: {
          field: 'drivingTimeEnd',
        }
      })
      if (type === 'defeat') {
        const failCodesList = await baseDataServices.defeatClueTreeNew({ bizType: 1 })
        ret.push({
          id: uuidv4(),
          type: 'commonCascader',
          label: this.$t('战败原因'),
          field: 'defeatFailCodes',
          tagsInput: true,
          valueType: 'checkedValues',
          options: failCodesList.map((item) => {
            return {
              id: uuidv4(),
              name: item.reason,
              value: item.id,
              children:
                item.children && item.children.length
                  ? item.children.map((item1) => {
                    return {
                      id: uuidv4(),
                      name: item1.reason,
                      value: item1.id,
                      children:
                        item1.children && item1.children.length
                          ? item1.children.map((item2) => {
                            return {
                              id: uuidv4(),
                              name: item2.reason,
                              value: item2.id,
                              children: [],
                            }
                          })
                          : [],
                    }
                  })
                  : [],
            }
          }),
        })
      }
      if (type === 'invalid') {
        const failCodesList = await invalidManageServices.getReasonList({})
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('无效原因'),
          multiple: true,
          field: 'invalidFailCodes',
          options: failCodesList.map((item) => {
            return {
              id: uuidv4(),
              label: item.reason,
              value: item.id,
            }
          }),
        })
      }
      if (this.isDealer) {
        const dccStaffList = await baseDataServices.getStaffList({ roleLogo: '1014001,1014002', dealerId: this.userInfo.dealers[0]?.id })
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('原跟进销售顾问'),
          field: 'lastUserIds',
          multiple: true,
          options: dccStaffList.map((item) => ({ ...item, label: item.name, value: item.id })),
        })
      }
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('试驾情况'),
        multiple: false,
        field: 'testDrivingCount',
        options: [
          { id: uuidv4(), label: this.$t('否'), value: 0 },
          { id: uuidv4(), label: this.$t('1次'), value: 1 },
          { id: uuidv4(), label: this.$t('多次'), value: 2 },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('购车意向等级'),
        multiple: true,
        field: 'leadLevels',
        options: [
          { id: uuidv4(), label: 'H', value: 'H' },
          { id: uuidv4(), label: 'A', value: 'A' },
          { id: uuidv4(), label: 'B', value: 'B' },
          { id: uuidv4(), label: 'C', value: 'C' },
          { id: uuidv4(), label: 'U', value: 'U' },
          { id: uuidv4(), label: '未评级', value: '' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('试驾意向等级'),
        multiple: true,
        field: 'tryDriveLevels',
        options: [
          ...getOptionsByDictType(1012),
          { id: uuidv4(), label: '未评级', value: '' },
        ],
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: type === 'defeat' ? this.$t('是否长期战败') : this.$t('是否长期无效'),
        field: type === 'defeat' ? 'defeatTimes' : 'invalidTimes',
        options: [
          { id: uuidv4(), label: this.$t('是'), value: 2 },
          { id: uuidv4(), label: this.$t('否'), value: 0 },
        ],
      })
      if (type === 'defeat') {
        ret.push({
          id: uuidv4(),
          type: 'options',
          label: this.$t('客户不愿被联系'),
          field: 'customerNoChat',
          options: [
            { id: uuidv4(), label: this.$t('是'), value: 1 },
            { id: uuidv4(), label: this.$t('否'), value: 0 },
          ],
        })
      }
      // 渠道，新版
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('用户来源'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
      })
      this.defeatFilterOptions = ret
    },
    init() {
      const { query } = this.$route
      const { parentCode='', childCode='', dealerProvinceCode='', dealerCityCode='', dealerIds='' } = query
      if (parentCode) {
        this.$set(this.filterParams,'parentCode', parentCode)
      }
      if (childCode) {
        this.$set(this.filterParams,'childCode',[childCode])
      }
      if (dealerProvinceCode) {
        this.$set(this.filterParams,'dealerProvinceCode',dealerProvinceCode)
      }
      if (dealerCityCode) {
        this.$set(this.filterParams,'dealerCityCode',dealerCityCode)
      }
      if (dealerIds) {
        this.$set(this.filterParams,'dealerIds',[dealerIds])
      }
    },
  }
}
