<script>
import SearchBarNew from '@/components/search-bar-new'
import VanTabs from '@/components/VanTabs'
import TabTags from '@/components/tab-tags'
import List from '@/components/baseList/list.vue'
import FilterPanel from '@/components/filter-panel'
import commonItemVue from './component/commonItem.vue'
import ListSort from '@/components/list-sort/Index.vue'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import mixin from './mixin'
import commonMixin from './commonMixin'
import { mapGetters } from 'vuex'

import ClueDistributionServices from '@/services/clueDistributionServices'
import { ROLES } from '@/constants/constants'
let vm
export default {
  components: { SearchBarNew, VanTabs, TabTags, List, FilterPanel, commonItemVue, BusinessFilters, ListSort },
  filters: {
    roleName({ followUserRoleCode }){
      return followUserRoleCode ? {
        '1014002': 'Sales',
        '1014001': 'DCC'
      }[followUserRoleCode] + (window.navigator.language.toLocaleLowerCase().includes('en') ? ' '+vm.$t('跟进') : vm.$t('跟进'))
        : ''
    }
  },
  mixins: [mixin, commonMixin],
  props: {
    outsideTab: {
      type: Array,
      default: () => ([])
    },
    tabValue: {
      type: [Number,String],
      default: ''
    }
  },
  data() {
    const that = this
    return {
      searchStr: '',
      activeTab: 3,
      tabList: [
        {
          text: that.$t('店内超期'), value: 3, key: 'leadCount',
          get total() {
            return that.tabCount.dealerIn || 0
          },
        },
        {
          text: that.$t('同城超期'), value: 4, key: 'leadAppointment',
          get total() {
            return that.tabCount.dealerOut || 0
          },
        },
      ],
      tabCount: {},
      tagValue: 4,
      actions: [
        { text: that.$t('重新分配'), key: 'distribute', premission: 'BTN-CLUE-DISTRIBUTE' },
        { text: that.$t('新建线索'), key: 'create', premission: 'BTN-CLUE-CREAT' },
        { text: that.$t('店内线索查询'), key: 'search' },
      ],
      tags: [
        {
          name: that.$t('首次超期'),
          value: 4,
          get num() {
            const { tabCount, activeTab } = that
            return (activeTab === 3 ? tabCount.inFirst : tabCount.outFirst) || 0
          },
        },
        {
          name: that.$t('跟进中超期'),
          value: 5,
          get num() {
            const { tabCount, activeTab } = that
            return (activeTab === 3 ? tabCount.inFollow : tabCount.outFollow) || 0
          },
        },
      ],
      checkedObj: {},
      followUserRoleCode: '',
      filterParams: {},
      sort: [{
        field: 'recycle_time',
        asc: false
      }],
      sortOptions: [
        {
          field: 'recycle_time',
          text: this.$t('回收时间'),
          order: 'descend'
        },
        {
          field: 'try_drive_level',
          text: this.$t('试驾意向')
        },
        {
          field: 'lead_level',
          text: this.$t('购车意向')
        },
      ],
      showPopover: false
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    getCheckLength() {
      return Object.keys(this.checkedObj).length
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    isAll() {
      try {
        const { checkedObj, $refs } = this
        return Object.keys(checkedObj).length > 0 && Object.keys(checkedObj).length === $refs.list.list.length
      } catch (error) {
        return ''
      }
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [], type } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
  },
  watch: {
    isShopManage: {
      handler(val) {
        if (!val) {
          this.tabList = this.tabList.filter(({ value }) => { return value != 4 })
        }
      },
      immediate: true
    },
  },
  mounted() {
    vm = this
  },
  created() {
    if (this.userInfo.type === 0) {
      this.init()
    }
    const [one, two] = this.outsideTab
    this.activeTab = Number(one || 3)
    this.tagValue = Number(two || 4)
    this.getShareClueCount()
  },
  methods: {
    onSort(data) {
      this.sort = data
      this.onRefresh()
    },
    getRoleCodes() {
      const { activeTab, followUserRoleCode } = this
      return activeTab === 3 ? followUserRoleCode : ROLES.DCC
    },
    getFilterData(data) {
      const values = Object.values(this.checkedObj)
      values.map(({ followUserId }) => {
        for (const key in data) {
          const child = data[key]
          child.map((item, index) => {
            if (item.id === followUserId) {
              // child.splice(index, 1)
            }
          })
          if (child.length === 0) {
            delete data[key]
          }
        }
      })
      return data
    },
    removeCheck() {
      this.checkedObj = {}
      this.followUserRoleCode = ''
    },
    // 三级菜单
    changeTag(value, item) {
      this.tagValue = value
      this.removeCheck()
      this.onRefresh(1)
    },
    // 切换 tab需要重新请求列表数据
    changeTab(val) {
      this.activeTab = val
      this.removeCheck()
      this.onRefresh(1)
    },
    // 操作面板
    operation({ key }) {
      if (key === 'create') {
        this.$router.push({
          path: '/create-clue'
        })
      } else if (key === 'search') {
        this.$router.push({
          path: '/shop-clue-search'
        })
      }
    },
    onInput(value) {
      this.searchStr = value
    },
    onRefresh(isFalseCount) {
      this.$refs.list.onRefresh()
      if (!isFalseCount) {
        this.getShareClueCount()
      }
    },
    // 跳转至详情
    goDetail({ id, followUserRoleCode, followUserId }) {
      if ((this.getUserInfo.isManager && this.activeTab === 3) || this.getUserInfo.type === 0) {
        this.$router.push({
          path: '/distribution-detail',
          query: {
            id,
            activeTab: this.activeTab,
            followUserRoleCode,
            followUserId
          },
        })

      }
    },
    goClueDetail({ id }) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    },
    checkItem(item) {
      const { id, followUserRoleCode } = item
      if (this.followUserRoleCode && this.followUserRoleCode !== followUserRoleCode) {
        return this.$toast(this.$t('请选择同类型角色跟进的线索'))
      }
      if (!this.followUserRoleCode) {
        this.followUserRoleCode = followUserRoleCode
      }
      if (this.checkedObj[id]) {
        this.$delete(this.checkedObj, id)
        Object.keys(this.checkedObj).length === 0 && (this.followUserRoleCode = '')
      } else {
        this.$set(this.checkedObj, id, item)
      }
    },
    // 全选
    checkAll() {
      const { list } = this.$refs.list
      if (Object.keys(this.checkedObj).length) {
        this.checkedObj = {}
        this.followUserRoleCode = ''
        return
      }
      const checkedObj = {}
      if (list && list.length) {
        this.followUserRoleCode = list[0].followUserRoleCode
        list.map((item) => {
          const { id, followUserRoleCode } = item
          if (this.followUserRoleCode === followUserRoleCode) {
            checkedObj[id] = item
          }
        })
        // this.checkedObj = checkedObj
        this.$set(this, 'checkedObj', checkedObj)
        console.log(this.checkedObj, 888)
      }
    },
    next() {
      const ids = Object.keys(this.checkedObj)
      if (ids.length) {
        if (this.activeTab === 4) {
          this.assignCityShareCheck(ids).then(res => {
            if (res && res.id) {
              const { followUserName, userMobile } = res
              let str = ''
              str += `【${followUserName || ''}】`
              str += this.$t('正在跟进线索')
              str += `${userMobile || ''}`
              str += this.$t('无需领取')
              // this.$toast(`【${followUserName || ''}】正在跟进线索${userMobile || ''}，无需领取`)
              this.$toast(str)
            } else {
              this.showDistribution = true
            }
          })
          return
        }
        this.showDistribution = true
      } else {
        this.$toast(this.$t('请选择待分配的线索'))
      }
    },
    distributionFilter(params) {
      const { saIdList } = params
      const ids = Object.keys(this.checkedObj)
      if (saIdList.length) {
        const { name, value } = saIdList[0]
        this[this.activeTab === 3 ? 'setAssignRecycle' : 'setAssignCityShare']({
          empId: value,
          empName: name,
          ids,
          roleLogo: this.activeTab === 3 ? this.followUserRoleCode : ROLES.DCC
        }).then(res => {
          this.$toast.success(this.$t('分配成功'))
          this.checkedObj = {}
          this.followUserRoleCode = ''
          this.onRefresh()
        })
      } else {
        this.$toast(this.$t('请选择分配人员'))
      }
    },
    assignCityShareCheck(params) {
      return ClueDistributionServices.assignCityShareCheck(params)
    },
    setAssignCityShare(params) {
      return ClueDistributionServices.setAssignCityShare(params)
    },
    // 领取
    receive(item) {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定领取该线索'),
        confirmButtonColor: '#B9921A',
        cancelButtonText: this.$t('取消'),
        confirmButtonText: this.$t('立即领取'),
      }).then(() => {
        ClueDistributionServices.setReceiveRecycle({ id: item.id }).then(() => {
          this.$toast(this.$t('领取成功'))
          this.onRefresh()
          this.goClueDetail(item)
        })
      })
    },

    // 菜单数量
    getShareClueCount() {
      ClueDistributionServices.getShareClueCount({
        queryCityCount: this.userInfo.type === 1 ? true : false,
        searchStr: this.searchStr,
        ...this.filterParams,
      }).then(res => {
        this.tabCount = res
        this.$emit('setCount', { overDue: res.dealerIn + res.dealerOut })
      })
    },
    fetchApi(obj) {
      return ClueDistributionServices.getShareClueList({
        sort: this.sort,
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        queryType: this.activeTab,
        subType: this.tagValue
      })
    }
  }
}
</script>
<template>
  <div class="clue-distribution">
    <div :style="{position: 'sticky', zIndex: 20, top: '44px'}">
      <search-bar-new :placeholder-text="$t('请输入客户姓名、电话')" :str="searchStr" :showScreen="true"
        :actions="actions" :active="Object.values(filterParams).length > 0" @input="onInput" @search="() => { onRefresh() }"
        @screening="filterPanelVisible = true" @operation="operation">
      </search-bar-new>
      <!-- 筛选面板 -->
      <FilterPanel ref="filterPanel" v-model="filterPanelVisible" :options="filterOptions" @submit="onFilter" />
      <VanTabs :queryType="1" :tabList="tabList" @change="changeTab">
        <div slot="icon" class="slotBox">
          <van-popover
            v-model="showPopover"
            theme="dark"
            placement="left-start">
            <div class="stageTips" style="width: 230px;" >
              <p>{{ $t('店内超期：在总部启用线索回收规则时，店内未跟进的线索逾期，且超过缓冲期（新线索缓冲期为2小时，持续跟进的缓冲期为24小时），触发回收规则后被回收到店内超期池的线索, 需要店长及时重新分配销售顾问。') }}</p>
              <p style="margin-top: 4px;">{{ $t('同城超期：店内超期的线索，城市经理将其同城共享后，同城的其他门店可主动领取为店内线索。') }}</p>
            </div>
            <template #reference>
              <van-icon name="question-o" size="18px" color="#B9921A" @click.stop="showPopover=true"/>
            </template>
          </van-popover>
        </div>
      </VanTabs>
    </div>
    <ListSort style="position: sticky; z-index: 20; top: 132px;" :options="sortOptions" @change="onSort"></ListSort>
    <TabTags class="tags" :tags="tags" :type="tagValue" @change="changeTag" />
    <List ref="list" :key="(activeTab || 3) + '-' + (tagValue || 4)" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <commonItemVue :clue="item" :activeTab="activeTab" :userInfo="getUserInfo" @receive="receive"
          @goDetail="goDetail">
          <template v-if="userInfo.type === 1" slot="check">
            <div class="checkBox">
              <van-checkbox
                :value="checkedObj[item?.id]"
                class="check"
                shape="square"
                @click.stop="checkItem(item)">
              </van-checkbox>
              <div v-if="[3,4].includes(activeTab)">{{ item|roleName }}</div>
            </div>
          </template>
        </commonItemVue>
      </template>
    </List>
    <BusinessFilters v-if="showDistribution" ref="filter" v-model="showDistribution" :options="distributionOptions"
      :defaultActiveKey="defaultActiveKey" :buttonNameReset="$t('取消')" @reset="showDistribution = false"
      @submit="distributionFilter"></BusinessFilters>
    <van-goods-action v-if="isShopManage">
      <div class="bottom-btns">
        <div class="left">
          <van-checkbox :value="isAll" @click="checkAll">{{ $t('全选') }}</van-checkbox>
        </div>
        <div class="right" @click="next">{{ activeTab === 3 ? $t('重新分配') : $t('领取并分配') }}{{ getCheckLength ?
          `(${getCheckLength})` : '' }}</div>
      </div>
    </van-goods-action>
  </div>
</template>
<style lang="less" scoped>
@checkbox-color: #0D171A;
.stageTips{
  padding: 12px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
  width: 230px
}
.tags {
  /deep/ .tab {
    flex: none;
  }
}
/deep/ .slotBox{
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
}

.checkBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /deep/.van-checkbox__icon {
    font-size: 16px;

    .van-icon {
      border: 2px solid rgba(13, 23, 26, 0.45);
    }
  }

  /deep/.van-checkbox__icon--checked .van-icon {
    background-color: @checkbox-color;
    border-color: @checkbox-color;
    line-height: 1;
  }
}
</style>
