<script>
import Distribution from './distribution.vue'
import Common from './common.vue'
import Defeat from './Defeat.vue'
import ClueDistributionServices from '@/services/clueDistributionServices'

import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
export default {
  name: 'DistributionName',
  components: { 
    Distribution,
    Common,
    Defeat,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'DistributionName')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$refs.componentName[0].filterPanelVisible = false
      this.$refs.componentName[0].filterParams = {}
      this.$refs.componentName[0].$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'DistributionName')
    }
    next()
  },
  data() {
    const that = this
    return {
      type: 0,
      outsideTabs: [],
      countInfo: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    outsideTab() {
      return this.outsideTabs.slice(1)
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
    tabs() {
      const { type } = this.userInfo
      let arr = [
        {
          name: this.$t('待分配线索'),
          value: 0,
          total: this.countInfo['toBeAllocated'] || 0,
          componentName: 'Distribution',
          key: 'toBeAllocated',
        },
        {
          name: this.$t('超期线索'),
          value: 1,
          total: this.countInfo['overDue'] || 0,
          componentName: 'Common',
          key: 'overDue',
        },
        {
          name: this.$t('战败线索'),
          value: 2,
          total: this.countInfo['defeat'] || 0,
          componentName: 'Defeat',
          key: 'defeat'
        },
        {
          name: this.$t('无效线索'),
          value: 3,
          total: this.countInfo['invalid'] || 0,
          componentName: 'Defeat',
          key: 'invalid'
        }
      ]
      if (type === 1 && !this.isShopManage) {
        arr.splice(0,1)
      }
      return arr
    }
  },
  activated() {
    this.$refs.componentName[0].onRefresh()
    this.$refs.componentName[0].removeCheck()
    this.getCount()
  },
  created() {
    const { tabs } = this.$route.query
    if (tabs) {
      this.outsideTabs = tabs.split(',')
    }
    this.type = Number(this.outsideTabs[0] || 0)
    this.getCount()
  },
  methods: {
    refresh() {
      this.getCount()
    },
    onTabChange() {
      this.outsideTabs = []
    },
    async getCount() {
      let params = {}
      const { parentCode='',
        childCode='',
        dealerProvinceCode='',
        dealerCityCode='',
        dealerIds=''
      } = this.$route.query
      if (parentCode) {
        params.parentCode = parentCode
      }
      if (childCode) {
        params.childCode = [childCode]
      }
      if (dealerProvinceCode) {
        params.dealerProvinceCode = dealerProvinceCode
      }
      if (dealerCityCode) {
        params.dealerCityCode = dealerCityCode
      }
      if (dealerIds) {
        params.dealerIds = [dealerIds]
      }
      const res = await ClueDistributionServices.toBeAllocatedLeadCount(params)
      const result = {
        ...res,
        overDue: res.overdueInStore + res.sameCityToBeAllocated
      }
      this.countInfo = result
    },
    setCount(data) {
      this.countInfo = Object.assign(this.countInfo, data)
    }
  }
}
</script>
<template>
  <!-- <van-tabs v-if="isShopManage" v-model="type" class="tabs1" @change="onTabChange">
    <van-tab v-for="item in tabs" :key="item.value" :title="item.name" :name="item.value">
      <component :is="item.componentName" v-if="item.value === type" ref="componentName" :outsideTab="outsideTab" />
    </van-tab>
  </van-tabs>
  <component :is="'Common'" v-else ref="componentName" :outsideTab="outsideTab" /> -->
  <van-tabs v-model="type" class="tabs1" swipe-threshold="1" @change="onTabChange">
    <van-tab v-for="item in tabs" :key="item.value" :name="item.value">
      <template #title>
        {{ `${item.name}（${item.total}）` }}
      </template>
      <component :is="item.componentName" v-if="item.value === type" ref="componentName" :tabValue="type" :outsideTab="outsideTab" @setCount="setCount" @refresh="refresh" />
    </van-tab>
  </van-tabs>
</template>
<style lang="less">
  .tabs1{
    >div {
      &.van-tabs__wrap{
        background: #fff;
        box-sizing: border-box;
        position: sticky;
        z-index: 20;
        top: 0
      }
    }
  }
  .bottom-btns {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 50px;

  .left {
    padding-left: 8px;
    display: flex;

    >span {
      padding: 0 8px;
      cursor: pointer;
    }
  }

  .right {
    background-color: #EED484;
    height: 100%;
    min-width: 120px;
    padding: 0 16px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
