<script>
import SearchBarNew from '@/components/search-bar-new'
import List from '@/components/baseList/list.vue'
import FilterPanel from '@/components/filter-panel'
import DefeatInvalidItem from './component/defeatInvalidItem.vue'
import mixin from './mixin'
import commonMixin from './commonMixin'
import { mapGetters } from 'vuex'
import clueServices from '@/services/clueServices'
import ClueDistributionServices from '@/services/clueDistributionServices'
import { ROLES } from '@/constants/constants'
import ListSort from '@/components/list-sort/Index.vue'
import TransferClue from '@/modules/clue/component/transferClue.vue'

let vm
export default {
  components: { SearchBarNew, List, FilterPanel, DefeatInvalidItem, ListSort, TransferClue },
  filters: {
    roleName({ followUserRoleCode }){
      return followUserRoleCode ? {
        '1014002': 'Sales',
        '1014001': 'DCC'
      }[followUserRoleCode] + (window.navigator.language.toLocaleLowerCase().includes('en') ? ' '+vm.$t('跟进') : vm.$t('跟进'))
        : ''
    }
  },
  mixins: [mixin, commonMixin],
  props: {
    tabValue: {
      type: Number,
      default: 2, // 2战败 3无效
    }
  },
  data() {
    return {
      searchStr: '',
      checkedObj: {},
      followUserRoleCode: '',
      filterParams: {},
      defeatFilterOptions: [],
      sort: [{
        field: this.tabValue === 2 ? 'defeat_time' : 'invalid_time',
        asc: false
      }],
      sortOptions: [
        {
          field: this.tabValue === 2 ? 'defeat_time' : 'invalid_time',
          text: this.tabValue === 2 ? this.$t('最新战败时间') : this.$t('最新无效时间'),
          order: 'descend'
        },
        {
          field: 'try_drive_level',
          text: this.$t('试驾意向')
        },
        {
          field: 'lead_level',
          text: this.$t('购车意向')
        },
      ],
      showTransfer: false,
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    getCheckLength() {
      return Object.keys(this.checkedObj).length
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    isAll() {
      try {
        const { checkedObj, $refs } = this
        return Object.keys(checkedObj).length > 0 && Object.keys(checkedObj).length === $refs.list.list.length
      } catch (error) {
        return ''
      }
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
    distributionIds() {
      return Object.keys(this.checkedObj)
    }
  },
  watch: {
    dictHash: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.createDefeatFilterOptions(this.tabValue === 2 ? 'defeat' : 'invalid')
        }
      },
      deep: true
    },
  },
  mounted() {
    vm = this
  },
  created() {
  },
  methods: {
    onSort(data) {
      this.sort = data
      this.onRefresh()
    },
    getRoleCodes() {
      return this.followUserRoleCode
    },
    removeCheck() {
      this.checkedObj = {}
      this.followUserRoleCode = ''
    },
    onInput(value) {
      this.searchStr = value
    },
    onRefresh() {
      this.$refs.list.onRefresh()
    },
    // 跳转至详情
    goClueDetail({ id }) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    },
    checkItem(item) {
      const { id, followUserRoleCode } = item
      if (this.followUserRoleCode && this.followUserRoleCode !== followUserRoleCode) {
        return this.$toast(this.$t('请选择同类型角色跟进的线索'))
      }
      if (!this.followUserRoleCode) {
        this.followUserRoleCode = followUserRoleCode || '1014001, 1014002'
      }
      if (this.checkedObj[id]) {
        this.$delete(this.checkedObj, id)
        Object.keys(this.checkedObj).length === 0 && (this.followUserRoleCode = '')
      } else {
        this.$set(this.checkedObj, id, item)
      }
    },
    // 全选
    checkAll() {
      const { list } = this.$refs.list
      if (Object.keys(this.checkedObj).length) {
        this.checkedObj = {}
        this.followUserRoleCode = ''
        return
      }
      const checkedObj = {}
      if (list && list.length) {
        this.followUserRoleCode = list[0].followUserRoleCode
        list.map((item) => {
          const { id, followUserRoleCode } = item
          if (this.followUserRoleCode === followUserRoleCode) {
            checkedObj[id] = item
          }
        })
        this.$set(this, 'checkedObj', checkedObj)
      }
    },
    onDistribution() {
      const ids = Object.keys(this.checkedObj)
      if (ids.length) {
        this.showTransfer = true
      } else {
        this.$toast(this.$t('请选择待分配的线索'))
      }
    },
    transferConfirm() {
      this.showTransfer = false
      this.onRefresh()
      this.$emit('refresh')
    },
    // 领取
    receive(item) {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定领取该线索'),
        confirmButtonColor: '#B9921A',
        cancelButtonText: this.$t('取消'),
        confirmButtonText: this.$t('立即领取'),
      }).then(() => {
        clueServices.receiveClue({ id: item.id }).then(() => {
          this.$toast(this.$t('领取成功'))
          this.goClueDetail(item)
        })
      })
    },
    async fetchApi(obj) {
      const res = await clueServices.getclueList({
        sort: this.sort,
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        firstQueryType: this.tabValue === 2 ? 14 : 15
      })
      this.totalCount = res.page.totalCount
      this.$emit('setCount', { [this.tabValue === 2 ? 'defeat' : 'invalid']: res.page.totalCount })
      return res
    }
  }
}
</script>
<template>
  <div :class="['clue-defeat', isShopManage ? 'clue-defeat-pd' : '']">
    <div :style="{position: 'sticky', zIndex: 20, top: '44px'}">
      <search-bar-new :placeholder-text="$t('请输入客户姓名、电话')" :str="searchStr" :showScreen="true"
        :active="Object.values(filterParams).length > 0" @input="onInput" @search="() => { onRefresh() }"
        @screening="filterPanelVisible = true">
      </search-bar-new>
      <!-- 筛选面板 -->
      <FilterPanel ref="filterPanel" v-model="filterPanelVisible" :options="defeatFilterOptions" @submit="onFilter" />
      <ListSort :options="sortOptions" :paddingTop="'0px'" @change="onSort"></ListSort>
    </div>
    <div class="totalCount">
      {{ $t('共') }} {{ totalCount}} {{ $t('条数据') }}
    </div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <DefeatInvalidItem :clue="item" :userInfo="getUserInfo" :tabValue="tabValue" @receive="receive"
          @goDetail="goClueDetail">
          <template v-if="userInfo.type === 1" slot="check">
            <div class="checkBox">
              <van-checkbox
                :value="checkedObj[item?.id]"
                class="check"
                shape="square"
                @click.stop="checkItem(item)">
              </van-checkbox>
              <div>{{ item|roleName }}</div>
            </div>
          </template>
        </DefeatInvalidItem>
      </template>
    </List>
    <!-- 分配线索 -->
    <TransferClue
      v-if="showTransfer"
      ref="transferRef"
      v-model="showTransfer"
      :distributionRole="followUserRoleCode"
      :distributionIds="distributionIds"
      @close="showTransfer = false"
      @transferConfirm="transferConfirm">
    </TransferClue>
    <van-goods-action v-if="isShopManage">
      <div class="bottom-btns">
        <div class="left">
          <van-checkbox :value="isAll" @click="checkAll">{{ $t('全选') }}</van-checkbox>
        </div>
        <div class="right" @click="onDistribution">{{ $t('分配') }}</div>
      </div>
    </van-goods-action>
  </div>
</template>
<style lang="less" scoped>
@checkbox-color: #0D171A;

.totalCount {
  color: @black;
  padding: 10px 16px 0 16px;
  font-size: 12px;
}

.tags {
  /deep/ .tab {
    flex: none;
  }
}
.clue-defeat{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &.clue-defeat{
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
  }
}

.checkBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  /deep/.van-checkbox__icon {
    font-size: 16px;

    .van-icon {
      border: 2px solid rgba(13, 23, 26, 0.45);
    }
  }

  /deep/.van-checkbox__icon--checked .van-icon {
    background-color: @checkbox-color;
    border-color: @checkbox-color;
    line-height: 1;
  }
}
</style>
