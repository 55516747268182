<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { i18n } from '@/main'
let vm
export default {
  components:{ shortConsumerInfo, CardOptions },
  filters: {
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    }
  },
  props:{
    clue:{
      type:Object,
      default:()=>({})
    },
    userInfo:{
      type: Object,
      default:()=>({})
    },
    tabValue: {
      type: Number,
      default: 2, // 2战败 3无效
    }
  },
  data() {
    return {
      i18n
    }
  },
  computed:{
    // 是否有领取的按钮 顾问/DCC 可以直接领取
    getRoleReceive() {
      const roleLogos = this.userInfo.roleLogos
      return roleLogos.some((item) => { return ['1014001', '1014002'].includes(item) })
    }
  },
  mounted() {
    vm = this
  },
  methods:{
  }
}
</script>
<template>
  <div class="clue-item">
    <div class="info" @click.stop="$emit('goDetail',clue)">
      <div class="row">
        <shortConsumerInfo :data="clue || {}" />
      </div>
      <CardOptions :data="clue"/>
      <div class="van-ellipsis">
        <span class="label">{{ $t('用户来源') }}：</span>
        <span v-if="clue.channelOne || clue.channelTwo" class="channel-name">{{ clue | sourceText }}</span>
      </div>
      <div class="van-ellipsis">
        <span class="label">{{ $t('原跟进') }}{{ clue.followUserRoleCode === '1014001'? $t('DCC专员'): $t('销售顾问') }}：</span>
        <span>{{clue.lastUserName || '--'}}</span>
      </div>
      <template v-if="tabValue === 2">
        <div class="van-ellipsis">
          <span class="label">{{ $t('最新战败时间：') }}</span>
          <span>{{clue.defeatTime || '--'}}</span>
        </div>
        <div class="van-ellipsis">
          <span class="label">{{ $t('最新战败原因：') }}</span>
          <span>{{ clue.defeatFailName }}</span>
        </div>
      </template>
      <template v-else>
        <div class="van-ellipsis">
          <span class="label">{{ $t('最新无效时间：') }}</span>
          <span>{{clue.invalidTime || '--'}}</span>
        </div>
        <div class="van-ellipsis">
          <span class="label">{{ $t('最新无效原因：') }}</span>
          <span>{{ clue.invalidFailName }}</span>
        </div>
      </template>
    </div>
    <div v-if="getRoleReceive" class="bottom">
      <div class="btn-yellow" @click.stop="$emit('receive',clue)">{{ $t('领取') }}</div>
    </div>
    <slot name="check"></slot>
  </div>
</template>
<style lang="less" scoped>
@lineColor: #F3F3F4;
.label{
  color: #7D7D7D;
}
.clue-item{
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px ;
  padding: 16px 12px 12px;
  font-size: 13px;
  position: relative;
  .info{
    position: relative;
    .row {
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(13,23,26,0.05);
    }
    >div:not(.row){
      margin-bottom: 8px;
    }
    .value{
      display: inline-block;
      width: 50%;
      vertical-align: middle;
    }
  }
  .btn-yellow{
    color: #B9921A;
  }
  .bottom{
    border-top: 1px solid @lineColor; 
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    >div{
      padding: 8px 8px 0;
      cursor: pointer;
    }
  }
}

</style>
